import React from 'react';
import '../../css/wedareEat.css';
import '../../css/animate.css';
import {Fade} from '../index';
import {Link} from 'react-router-dom';



export function Quote(props){
    return(
        <section id="who" className="rowMin sectionBlock alignCenter animateEl  fadeInUp animated" data-animate="fadeInUp" data-duration=".6s" data-delay="0.2s">
			<div className="rowMin" >
			<Fade bottom>
				<h1>{props.title}</h1>
				<br />
				<section className="alignLeft dflex" >
				<div className="paddingLarge">
				<p className="WhiteText">{props.content1}</p>
				<p className="WhiteText">{props.content2}</p>
				<p className="WhiteText">{props.content3}</p>
				</div>
				<div className="paddingLarge">
				<p className="WhiteText">{props.content4}</p>
				<p className="WhiteText">{props.content5}</p>
				<br />
				<b><p className="WhiteText">-Albert Einstein, 1929</p></b>
				</div></section>
				
				
				
				</Fade>
				{props.product &&
				<span><a className="btn" href="prezzi.html" title="Acquistalo subito">Provalo subito!</a><Link to = "/caratteristiche"> <a className="btn" href="caratteristiche.html" title="Dettagli">Scopri i dettagli</a></Link></span>
				}
			</div>
		</section>
    );
}