import React from 'react';
import '../../css/wedareEat.css';
import '../../css/animate.css';
import {useLocation} from 'react-router-dom';
import {Fade} from '../index';
import {Link} from 'react-router-dom';



export function WhatToDoNow(){
	const location = useLocation();  //return the jsw that matches the correct path location

	if(location.pathname === "/"){
    return(
		<Fade>
        <section className="rowMin sectionBlock blockContatti alignCenter animateEl  fadeIn animated" data-animate="fadeIn" data-duration=".6s" data-delay="0.2s">
			
			<header>
				<h1>Cosa puoi fare ora? </h1>
				<h2>Dai un'occhiata ai vantaggi che avrai con noi o contattaci</h2>
			</header>
			<Link to="/caratteristiche"><a className="btn" href="contatti.html" title="Contattaci" style={{marginRight: '5px'}}   >Vantaggi</a></Link>
			<Link to="/contatti"><a className="btn" href="contatti.html" title="Contattaci" style={{marginLeft: '5px'}}>Contattaci</a></Link>
			
		</section>
		</Fade>
	);}
	
	else if(location.pathname === "/caratteristiche"){
		return(
			<Fade>
			<section className="rowMin sectionBlock blockContatti alignCenter animateEl  " data-animate="fadeIn" data-duration=".6s" data-delay="0.2s">
				
			<header>
				<h1>Cosa puoi fare ora? </h1>
				<h2>Scopri le nostre soluzioni su misura per te o contattaci</h2>
			</header>
			<Link to="/prezzi"><a class="btn" href="contatti.html" title="Contattaci" style={{marginRight: '5px'}}>Soluzioni</a></Link>
			<Link to="/contatti"><a class="btn" href="contatti.html" title="Contattaci" style={{marginLeft: '5px'}}>Contattaci</a></Link>
			
		</section>
		</Fade>
		);
	}

	else if(location.pathname === "/prezzi"){
		return(
			<Fade>
		<section className="rowMin sectionBlock blockContatti alignCenter animateEl  " data-animate="fadeIn" data-duration=".6s" data-delay="0.2s">
			
		<header>
			<h1>Chiedici di più</h1>
			<h2>Semplicissimo, veloce e sicuro</h2>
		</header>
		<Link to="/contatti"><a className="btn" href="contatti.html" title="Contattaci">Contattaci</a></Link>
		
	</section>
		</Fade>
		);}
}