import React from 'react';
import '../../css/wedareEat.css';
import '../../css/animate.css';
import logo from '../../img/wecommerce-logo-flat.png';
import { useTranslation } from 'react-i18next';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
	useLocation
  } from 'react-router-dom';

import AnchorLink  from 'react-anchor-link-smooth-scroll';

export function MobileMenu(){
	const { t, i18n } = useTranslation();


/*menu
$(".openMenuMob").on("click", function(){
	$("body").toggleClass("menuOpened")
});
$(document).mouseup(function(e) {
	var container = $(".menuLateraleMobile");
	if (!container.is(e.target) && container.has(e.target).length === 0) 
	{
		$("body").removeClass("menuOpened")
	}
});
//fine menu*/

	const handleClick_openMenu = () => {
		document.querySelector("body").classList.add("menuOpened");
	};

	const handleClick_closeMenu = () => {
		document.querySelector("body").classList.remove("menuOpened");
	};

    return(
        <content>
        <div className="mobileMenu">
		<a href="index.html" title="WEDARE-EAT"><img src={logo} alt="Wedare EAT, l'applicazione perfetta per la tua attività di ristorazione" /></a>
		<span className="lnr lnr-menu openMenuMob" onClick={handleClick_openMenu}></span>
	    </div>
	<div className="menuLateraleMobile">
		<span className="openMenuMob lnr lnr-cross" onClick={handleClick_closeMenu}></span>
		<a href="minisite-superIndex.html" title="WeDare.eat"><img className="logo" src={logo} alt="wedare.eat" /></a>
		<ul className="row mainMenu maxH">
			<AnchorLink href="#who" className="mobOption"><li className={`upperCase ${window.location.pathname === "/" ? "sel" : ""}`} ><a href="index.html" title="WeDare.eat home" onClick={handleClick_closeMenu}>chi siamo</a></li></AnchorLink>
			<AnchorLink href="#prods" className="mobOption"><li className={`upperCase ${window.location.pathname === "/caratteristiche" ? "sel" : ""}`}><a href="caratteristiche.html" title="WeDare.eat caratteristiche" onClick={handleClick_closeMenu}>i nostri servizi</a></li></AnchorLink>
			<AnchorLink href="#conts" className="mobOption"><li className={`upperCase ${window.location.pathname === "/prezzi" ? "sel" : ""}`}><a href="prezzi.html" title="WeDare.eat Prezzi"onClick={handleClick_closeMenu}>contattaci</a></li></AnchorLink>
		</ul>
		<ul className="row endMenu cambiaLingua">
			<li className="block">Select Language</li>
			<li><a href="/" title="EN">EN</a> - </li>
			<li><a href="/" title="FR">FR</a> - </li>
			<li><a href="/" title="DE">DE</a></li>
		</ul>
	</div>

	<section  className="rowMin  alignCenter animateEl  bottom-fixed" data-animate="fadeInUp" data-duration=".6s" data-delay="0.2s" >
		<div className="rowMin">
			<p className="WhiteText" style={{marginTop: '5px'}}>{t('sticky_footer')}</p>
		</div>
	</section>
    </content>
    );
}