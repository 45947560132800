import React from 'react';
import{
    Link
}from 'react-router-dom';
import AnchorLink from 'react-anchor-link-smooth-scroll';


export function SliderComponent(props){
    return(
      
        <div className="row slideEl slideEl1" >
        <section className="row slideElWrapper homeSliderA"> 
            <div className="textBox">
                <header>
                    <h1>{props.h1}</h1>
                    <h2>{props.h2}</h2>
                </header>
               <AnchorLink href="#conts">Lo voglio!</AnchorLink>
            </div>
            <img src={props.img} alt="" />
        </section>
    </div>
    
    );
}