import React from 'react';
import '../../css/wedareEat.css';
import '../../css/animate.css';
import img5 from '../../img/eat/webMockupCaract.jpg';


import wedareeat from '../../img/weeatiphone.png';
import docdoor from '../../img/livetheatermock.png';

import {Fade} from '../index';


import AnchorLink from 'react-anchor-link-smooth-scroll';
import { HashLink as Link } from 'react-router-hash-link';



export function StartFrom(props){
    return(
       
        <section className="row sectionBlock bgAndRight" id={props?.idref}>
        <div className="blockOverflow">
            <Fade>
            <img src={props.image}  alt=""/>
            </Fade>
            <Fade bottom>
            <div className="textBox animateEl  fadeInUp animated" data-animate="fadeInUp" data-duration=".6s" data-delay="0.2s">
            
                <header className="row">
                    <h1>{props.title}</h1>
                    <h2>{props.subtitle}</h2>
                </header>
                
                <p className="animateEl  fadeInUp animated">{props.text1}</p>
                <p className="animateEl  fadeInUp animated"><b>{props.text2}</b></p>
                <AnchorLink href='#conts'><a className="btn animateEl  fadeInUp animated"  title="Acquistalo subito">Scopri di più</a></AnchorLink>
               
            </div>
            </Fade>
            
        </div>
    </section>
   
    );
}