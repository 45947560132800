import React from 'react';
import '../../css/wedareEat.css';
import '../../css/animate.css';

export class checkoutBox extends React.Component{
    render(){
        return(
            <div className="checkoutBox">
                <span className="openCheck lnr lnr-cross"></span>
                <div className="checkoutBoxWrap">
                    <div className="topperCheckout">
                        <span className="titoloBig">Attivazione</span>
                        <span className="titoloServizio">Piano mensile <span className="dataPianoIn">Gold</span></span>					
                        <div className="tabellaPrezziList">
                            <div className="tabellaPrezzi row">
                                <var className="featuresList"></var>
                            </div>
                        </div>
                    </div>		
                    <p className="totalCheck">Piano mensile "<span className="dataPianoIn">xxx</span>" <span className="priceSpan">€ <span className="dataPriceIn">xxx</span> /mese</span></p>
                    <form className="row newAccount visible">				
                        <input type="text" name=""  required />
                        <input type="text" name=""  required />
                        <input type="text" name=""  required />
                        <input type="text" name=""  required />				
                        <button className="btn" type="submit">Registrati e paga</button>
                        <p className="switchLogin">Hai già un account? <span className="bold">Fai il login</span></p>				
                    </form>
                    <form className="row login">				
                        <input type="text" name="" placeholder="Username" required />
                        <input type="password" name="" placeholder="Password" required />				
                        <button className="btn" type="submit">Accedi e paga</button>
                        <p className="switchLogin">Non hai un account? <span className="bold">Registrati</span></p>
                    </form>
                </div>
            </div>
           
        );
    }
}