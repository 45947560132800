import React from 'react';
import {listenToScroll} from '../listenToScroll';
import {Menu, PensiamoATuttoNoi, CaratteristicheSec1, CaratteristicheSec2, CaratteristicheSec3, CaratteristicheSec4, CaratteristicheSec5, WhatToDoNow, MobileMenu} from '../components/index';



export class  Caratteristiche extends React.Component{

    constructor(props){
        super(props);
        this.state = {

        Solutions: [{icon:'lnr-store', text:'creiamo la tua pagina', upper:'Noi'},
        {icon:'lnr-picture', text:'impostiamo i prodotti', upper:'Noi'},
        {icon:'lnr-bubble', text:'ti diamo i consigli', upper:'Noi'},
        {icon:'lnr-smile', text:"Vivi l'esperienza", upper:'Noi'},
        ]
    }
    }

    componentDidMount(){
        window.addEventListener('scroll', listenToScroll)
    }
    componentWillUnmount(){
        window.addEventListener('scroll', listenToScroll)
    }
render(){
    return(
        <div className="tipo wedareeatDetails" >
        <div className="row wrapPage">
            <Menu />
            <CaratteristicheSec1 />
            <CaratteristicheSec2 />
            <CaratteristicheSec3 />
            <CaratteristicheSec4 />
            <PensiamoATuttoNoi
            title="Pensiamo a tutto noi!" subtitle="Sappiamo quanto è prezioso il tuo tempo, ecco perchè..."
            content={this.state.Solutions}
            />
            <CaratteristicheSec5 />
            <WhatToDoNow />
            <MobileMenu />
        </div>
        </div>
    );}
}