import React from 'react';
import '../../css/wedareEat.css';
import '../../css/animate.css';
import menuDigitale from '../../img/eat/menuDigitale.jpg';
import smartphonesWhite from '../../img/WhiteMockups/smartphoneswhite_5.png';
import {Fade} from '../index';


export function CaratteristicheSec2(){
    return(
        <section className="row sectionBlock">
			<div className="blockOverflow">
				<Fade bottom>
				<div className="textBox animateEl  " data-animate="fadeIn" data-duration=".6s" data-delay="0.2s">
					<header className="row">
						<h1>Molto più di un semplice e-commerce</h1>
						<h2 className="WhiteText">La scelta migliore a 360°</h2>
					</header>
					<p className="WhiteText">Non ci fermiamo solo al sito web, ti vogliamo garantire la massima qualità a 360 gradi, dall'impostazione di un nuovo prodotto fino all'esperienza in negozio.</p>
					<p className="WhiteText">Potrai scegliere la strategia che più fa al caso tuo, sarai tu a decidere se concentrarti sulle vendite online o se sfruttra il potenziale di WeCommerce per portare i tuoi clienti in negozio.</p>
					
					<a className="btn" href="prezzi.html" title="Acquistalo subito">Provala subito!</a>
				</div>
				</Fade>
				<img src={smartphonesWhite} className="animateEl  fadeInUp animated" data-animate="fadeInUp" data-duration=".6s" data-delay="0.2s"  alt=""/>
			</div>
		</section>
    );
}