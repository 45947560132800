import React from 'react';
import { Menu, ParallaxBg, PensiamoATuttoNoi, Quote, ShowCarousel, StartFrom, WhatToDoNow, MobileMenu, Fade} from '../components/index';
import {listenToScroll} from '../listenToScroll';
import whiteMockup1 from '../img/WhiteMockups/smartphoneswhite.png';
import { withTranslation } from 'react-i18next';


export class  Home extends React.Component{
    constructor(props){
        super(props);
        this.state = {SlidesContent : [{key:0, h1: "Rivivi il primo giorno con un click", h2: "Abbiamo reinventato l'ecommerce che va oltre l'ecommerce", img: whiteMockup1},
        {key:1, h1: "Più gente in negozio? vendiamo online!", h2: "I tuoi clienti potranno prenotarsi online per passare in negozio", img: whiteMockup1},
        {key: 2, h1: "Un commesso sempre nel tuo negozio", h2: "Il nostro commesso virtuale guiderà il tuo cliente nel negozio online.", img: whiteMockup1},
        {key:3, h1: "Ti aiutiamo a 360°", h2: "Andiamo oltre l'ecommerce e ti aiutiamo in ogni fase del processo di vendita, online e non solo.", img: whiteMockup1}, ],

        Solutions: [{icon:'lnr-store', text:'creiamo la tua pagina', upper:'Noi'},
        {icon:'lnr-picture', text:'impostiamo i prodotti', upper:'Noi'},
        {icon:'lnr-bubble', text:'ti diamo i consigli', upper:'Noi'},
        {icon:'lnr-smile', text:"Vivi l'esperienza", upper:'Noi'},
        ],
    }
    }
   
    componentDidMount(){
        window.addEventListener('scroll', listenToScroll)
    }
    componentWillUnmount(){
        window.addEventListener('scroll', listenToScroll)
    }
    
    
render(){
    const { t } = this.props;

    return(
    <div className="tipo wedareeatHome" onWheel={this.getScrollPosition}>
	<div className="row wrapPage">
            <Menu />
            <ShowCarousel slides={this.state.SlidesContent}/>
            <Quote
             title="Rivivi l'esperienza del primo giorno"
             content="Abbiamo creato WeCommerce tenendo bene a mente che non possiamo pretendere che le cose cambino se continuiamo a fare le stesse cose! ecco perchè non ci fermiamo al consegnarti 'un sito', andiamo oltre e ti consegnamento 'le chiavi' del tuo nuovo negozio. Lavoriamo con passione ogni giorno per i  piccoli imprenditori Italiani a cui vogliamo dare una mano per sfruttare al meglio le potenzialità del web usato non come strumento per sostituire ma come un plus per l'imprenditore e i suoi clienti vecchi e nuovi!"
             product={1}
             />
            <StartFrom 
            title="A partire da 10,99€/mese"
            subtitle="Il primo mese te lo regaliamo noi!"
            text1="Avrai il tuo negozio virtuale e imparerai da subito a gestire in autonomia il facilissimo sistema che WeCommerce ti offre.
			Potrai sfruttare dal giorno 0 tutta la suite di servizi che ti offriamo per migliorare da subito la tua esperienza e quella di tutti i tuoi clienti. "
            text2="Con WeCommerce vai oltre i limiti del semplice e-commerce!"
            />
            <ParallaxBg
            image="https://images.pexels.com/photos/2159065/pexels-photo-2159065.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"
            />
            
            <PensiamoATuttoNoi
            content={this.state.Solutions}
            title="Pensiamo a tutto noi!" subtitle="Sappiamo quanto è prezioso il tuo tempo, ecco perchè..."
            
            />
            
            <WhatToDoNow />
            <MobileMenu />
    </div>
    </div>
    );}
}