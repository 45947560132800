import React from 'react';
import {Fade} from '../index';

export function PrezziSec1(){
    return(
        <section className="rowMin sectionBlock alignCenter animateEl  " data-animate="fadeIn" data-duration=".6s" data-delay="0.2s">
        <Fade>
        <div className="row">
            <header>
                <h1>Abbonamento personalizzato</h1>
                <h2 className="WhiteText">Non hai trovato la soluzione adatta per la tua attività ? Dicci cosa ti serve e ti forniremo il pacchetto di cui hai bisogno.</h2>
            </header>
            <a href="contatti.html" title="contattaci" className="btn">Contattaci</a>
        </div>
        </Fade>
    </section>
    );
}