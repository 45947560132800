import React from 'react';
import caratteristicheBg from '../../img/eat/caratteristicheBg.jpg';
import smartphoneCircle from '../../img/WhiteMockups/smartphoneCircle.png';

import '../../css/wedareEat.css';
import '../../css/animate.css';

export function CaratteristicheSec1(){
    return(
        <div className="row topSlider internalSlider">
        <div className="row slideEl slideEl1">
            <section className="row slideElWrapper homeSliderA">
                <img src={smartphoneCircle} alt="Non solo menu digitale" />
                <div className="textBox">
                    <header>
                        <h1>A partire da 10,99€/mese</h1>
                        <h2>Il primo mese è gratis</h2>
                        <p>Scegli l'abbonamento perfetto per te e la tua attività.</p>
                    </header>
                    <a  href="prezzi.html" title="Acquistalo subito" >Lo voglio!</a>
                </div>
            </section>
        </div>
    </div>
    );
}