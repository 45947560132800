import React from 'react';
import '../../css/wedareEat.css';
import '../../css/animate.css';
import {useLocation} from 'react-router-dom';
import {Fade} from '../index';
import AnchorLink from 'react-anchor-link-smooth-scroll';


export function PensiamoATuttoNoi(props){
   

    const location = useLocation();

    return(
        <section id="prods" className={location.pathname === "/contatti" ? "row bgImgRowWrap parallaxEffectCss sectionBlock bgBlockParallax alignCenter" : "rowMin sectionBlock alignCenter"} >
        <div className="row">
            <header className="animateEl  fadeInUp animated" data-animate="fadeInUp" data-duration=".6s" data-delay="0.2s"> 
                <h1>{props.title}</h1>
                <h2  className="WhiteText">{props.subtitle}</h2>
            
            </header>
            <Fade bottom>
            <div className="row blocksS">
            {props.content.map(key => 

                <div className='animateEl  fadeIn animated' data-animate="fadeIn" data-duration=".6s" data-delay="0.2s">
                    <AnchorLink href={`#${key.ref}`} ><span className={`lnr ${key.icon} ${key.link && 'link-hover'}`}
                    ></span></AnchorLink>
                    <h3 className="WhiteText">
                        {key.upper && <span>{key.upper}</span>}
                        {key.text}</h3>
                </div>
            )}   				
            </div>
            </Fade>
        </div>
    </section>
    );
}


<section class="row bgImgRowWrap parallaxEffectCss sectionBlock bgBlockParallax alignCenter"></section>