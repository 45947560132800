import React from 'react';
import '../../css/wedareEat.css';
import '../../css/animate.css';

export function TablePrices(props){
        return(
            <section className="tabellaPrezzi shadow animateEl  " data-animate="fadeInUp" data-duration=".6s" data-delay="0.2s" data-price="10,99" data-piano="Starter">
						<header>
							<h2><var className="dataPianoIn">{props.PlanName}</var></h2>
							<h1 ><span>€</span> <var className="dataPriceIn">{props.PlanPrice}</var> <span>/mese</span></h1>						
							<span className="btn openCheck">Attiva</span>
						</header>
						<ul class="featuresList">
							{props.Features}
						</ul>						
					</section>
        );
}