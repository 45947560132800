import React from 'react';
import prenotaCart from '../../img/eat/prenotaCart.png';
import smartphonesWhite from '../../img/WhiteMockups/smartphoneswhite_5.png';
import '../../css/wedareEat.css';
import '../../css/animate.css';
import {Fade} from '../index';

export function CaratteristicheSec4(){
    return(
        <section className="row sectionBlock bgAndRight">
        <div className="blockOverflow">
            <Fade bottom>
            <img src={smartphonesWhite} className="animateEl  " data-animate="fadeInUp" data-duration=".6s" data-delay="0.2s" alt="" />
            </Fade>
            <div className="textBox animateEl  " data-animate="fadeIn" data-duration=".6s" data-delay="0.2s">
                <header className="row">
                    <h1>Non soltanto vendite online</h1>
                    <h2>Semplicissimo, veloce e sicuro! Con Wecommerce servono solo 5 minuti. </h2>
                </header>
                <p>In 5 minuti porterai il potenziale della tua attività online, semplificherai le tue attività quotidiane e anche quelle dei tuoi clienti che potranno sfruttare tutti i servizi a loro disposizione a portata di smartphone!</p>
                <p><b>In soli 5 minuti i tuoi clienti potranno ordinare un nuovo prodotto, prenotare la propria visita in negozio, chiedere informazioni, e tanto altro con WeCommerce. </b></p>
                <a className="btn" href="prezzi.html" title="Acquistalo subito">Consulta i prezzi</a>
            </div>				
        </div>
    </section>
    );
}