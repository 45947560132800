import React from 'react';
import '../../css/wedareEat.css';
import '../../css/animate.css';
import logo from '../../img/wecommerce-logo-flat.png';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useLocation
  } from 'react-router-dom';
  



export class Menu extends React.Component {


render(){
    
    return(
        <div className="row top" >
        <div className="row superTop">
            
        </div>
        <div className="row mainMenu" id="mainMenu">
            <header>
                <Link to="/"><h1><a className="logoTop" href="/" title="Wedare EAT, l'applicazione perfetta per la tua attività di ristorazione"><img src={logo} alt="Wedare EAT, l'applicazione perfetta per la tua attività di ristorazione" /></a></h1></Link>
            </header>
            <ul>
                 <Link to="/"><li className={window.location.pathname === "/" ? "sel" : ""}><a href="index.html" title="">Home</a></li></Link>
                 <Link to="/caratteristiche"><li className={window.location.pathname === "/caratteristiche" ? "sel" : ""}><a href="caratteristiche.html" title="Caratteristiche">Caratteristiche</a></li></Link>
                <Link to="/prezzi"><li className={window.location.pathname === "/prezzi" ? "sel" : ""}><a href="prezzi.html" title="Prezzi">Prezzi</a></li></Link>
                <Link to="/contatti"><li className={window.location.pathname === "/contatti" ? "sel" : ""}><a href="contatti.html" title="Contatti">Contatti</a></li></Link>
            </ul>
        </div>
    </div>
    );
}}
