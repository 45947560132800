import React from 'react';
import '../../css/wedareEat.css';
import '../../css/animate.css';
import {Fade} from '../index';

export function CaratteristicheSec3(){
    return(
        <section className="row bgImgRowWrap parallaxEffectCss sectionBlock bgBlockParallax alignCenter">
            <Fade bottom>
        <div className="row blocksS">
            <div className="animateEl  " data-animate="fadeIn" data-duration=".6s" data-delay="0.2s">
                <span className="lnr lnr-hourglass"></span>
                <h3>Assistenza salvatempo</h3>
            </div>
            <div className="animateEl  " data-animate="fadeIn" data-duration=".6s" data-delay="0.3s">
                <span className="lnr lnr-laptop-phone"></span>
                <h3>Negozio online</h3>
            </div>
            
            <div className="animateEl  " data-animate="fadeIn" data-duration=".6s" data-delay="0.4s">
                <span className="lnr lnr-chart-bars"></span>
                <h3>Dashboard su misura</h3>
            </div>
            <div className="animateEl  " data-animate="fadeIn" data-duration=".6s" data-delay="0.5s">
                <span className="lnr lnr-text-align-left"></span>
                <h3>Consigli di scrittura</h3>
            </div>					
        </div>
        </Fade>
    </section>
    );
}