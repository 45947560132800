import React from 'react';
import {Menu, PensiamoATuttoNoi, ContattiSec1, MobileMenu} from '../components/index';
import {listenToScroll} from '../listenToScroll';


export class Contatti extends React.Component{
    constructor(props){
        super(props);
        this.state = {

        Solutions: [{icon:'lnr-store', text:'creiamo la tua pagina', upper:'Noi'},
        {icon:'lnr-picture', text:'impostiamo i prodotti', upper:'Noi'},
        {icon:'lnr-bubble', text:'ti diamo i consigli', upper:'Noi'},
        {icon:'lnr-smile', text:"Vivi l'esperienza", upper:'Noi'},
        ]
    }
    }

    componentDidMount(){
        window.addEventListener('scroll', listenToScroll)
    }
    componentWillUnmount(){
        window.addEventListener('scroll', listenToScroll)
    }
  
    render(){
        return(
            <div class="tipo wedareeatDetails" >
            <div class="row wrapPage">
                <Menu />
                <ContattiSec1 />
                <PensiamoATuttoNoi
                title="Le nostre soluzioni" subtitle="Create per aiutare le imprese in questo periodo"
                content={this.state.Solutions}
                />
                <MobileMenu />
            </div>
            </div>
        );
    }
}