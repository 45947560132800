import React from 'react';
import '../../css/wedareEat.css';
import '../../css/animate.css';
import {TablePrices, Fade} from '../index';

export class Plans extends React.Component{
    constructor(props){
        super(props);
        this.state = {
        Plan1: {name: 'STARTER', price: '10,99', features: [
            "T_Attivazione gratuita", "T_Primo mese gratis", "T_QR code WedareEAT", "T_Menu Digitale", "T_Mini sito ecommerce", "F_Gestione ordini dal tavolo", "F_Prenotazione", "F_Kit segnaposti"
        ]},
        Plan2: {name: 'GOLD', price: '29,99', features: [
            "T_Attivazione gratuita", "T_Primo mese gratis", "T_QR code WedareEAT", "T_Menu Digitale", "T_Mini sito ecommerce", "T_Gestione ordini dal tavolo", "F_Prenotazione", "F_Kit segnaposti"
        ]},
        Plan3: {name: 'ELITE', price: '95', features: [
            "T_Attivazione gratuita", "T_Primo mese gratis", "T_QR code WedareEAT", "T_Menu Digitale", "T_Mini sito ecommerce", "T_Gestione ordini dal tavolo", "T_Prenotazione", "T_Kit segnaposti"
        ]}
          }
 }
render(){
        return(
            <section class="row priceBlock sectionBlock alignCenter">
            <div className="rowMin">
                <Fade>
				<div className="row textBox">
					<header className="row animateEl  " data-animate="fadeIn" data-duration=".6s" data-delay="0.2s">
						<h1>Scegli la soluzione su misura per te<template></template></h1>
						<h2 >Il rinnovo è automatico ma puoi disdire in qualsiasi momento</h2>
					</header>					
				</div>
                </Fade>
				<div className="row tabellaPrezziList alignCenter">
            <Fade bottom>
                <TablePrices 
                PlanName = {this.state.Plan1.name}
                PlanPrice = {this.state.Plan1.price}
                /*Features are displayed from an array in the state component and the icon is added based on the first letter of the string T= true, F=false */
                Features = {this.state.Plan1.features.map(key => {return(<li><span className={`lnr ${key[0] === 'T' ? "lnr-checkmark-circle" : "lnr-cross-circle"}`} ></span>{key.substr(2)}</li>)})}
                />
            </Fade>
            <Fade bottom>
                <TablePrices 
                PlanName = {this.state.Plan2.name}
                PlanPrice = {this.state.Plan2.price}
                /*Features are displayed from an array in the state component and the icon is added based on the first letter of the string T= true, F=false */
                Features = {this.state.Plan2.features.map(key => {return(<li><span className={`lnr ${key[0] === 'T' ? "lnr-checkmark-circle" : "lnr-cross-circle"}`} ></span>{key.substr(2)}</li>)})}
                />
            </Fade>
            <Fade bottom>

                <TablePrices 
                PlanName = {this.state.Plan3.name}
                PlanPrice = {this.state.Plan3.price}
                /*Features are displayed from an array in the state component and the icon is added based on the first letter of the string T= true, F=false */
                Features = {this.state.Plan3.features.map(key => {return(<li><span className={`lnr ${key[0] === 'T' ? "lnr-checkmark-circle" : "lnr-cross-circle"}`} ></span>{key.substr(2)}</li>)})}
                />
            </Fade>
               </div>
               </div>
               </section>
        );
     }
}



