import React from 'react';
import '../../css/wedareEat.css';
import '../../css/animate.css';
import {ContactForm} from '../index';
import { useTranslation } from 'react-i18next';
import { FormContatti } from './ContactForm';

export function ContattiSec1(){
	const { t, i18n } = useTranslation();

    return(
        <section id='conts' className="rowMin sectionBlock alignCenter marginTop animateEl  " data-animate="fadeIn" data-duration=".6s" data-delay="0.2s">
			<div className="textBox marginTop">
				<header className="row" style={{marginBottom: '30px'}}>
					<h1>{t('contatti_title')}</h1>
					<h2 className="WhiteText">{t('contatti_subtitle')}</h2>
				</header>
                <FormContatti />
			</div>
			<row style={{color:'white'}}>
				<p>Wedare Srl - Via E. Sartori, 6/A - PARMA - P. IVA IT02968660346 - REA PR281173</p>
			</row>
		</section>
    );
}