
export let listenToScroll = () => {
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
  
    if (winScroll >= 100){
        document.getElementById("mainMenu").style.top = 0;
    }
     if(winScroll < 100){
        document.getElementById("mainMenu").style.top = "20px";
    }
}