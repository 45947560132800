import React from 'react';
import {  ParallaxBg, PensiamoATuttoNoi, Quote, ShowCarousel, StartFrom, WhatToDoNow, MobileMenu, Fade, ContattiSec1} from '../components/index';
import { Menu, MenuLanding } from '../components/Landing/LandingMenu';
import {listenToScroll} from '../listenToScroll';
import {StartFromRight} from '../components/HomeOnly/StartFromRight';
import whiteMockup1 from '../img/WhiteMockups/smartphoneswhite.png';
import { withTranslation } from 'react-i18next';
import { ToastContainer, toast } from 'react-toastify';

//importing images
import livetheater from '../img//phmaterial/livetheater.png';
import wedareeat from '../img/phmaterial/weeat.png';
import docdoor from '../img/phmaterial/docdoor.png'
import white from '../img/phmaterial/nomock.png';
import work from '../img/phmaterial/workph.jpg';
import wecommerce from  '../img/phmaterial/commerce_mock.png';
import yotei from '../img/phmaterial/yotei_mock.png';

let slidesJson = require('../jsons/SlidesContent.json');


export class Landing extends React.Component{
   
    componentDidMount(){
        window.addEventListener('scroll', listenToScroll)
    }
    componentWillUnmount(){
        window.addEventListener('scroll', listenToScroll)
    }
    
render(){
    const { t } = this.props;

    const SlidesContent_var = [
        {key:0, h1: t('slide_main_title'), h2: t('slide_main_text'), img: work},
        {key: 2, h1: t('slide_weeat_title'), h2: t('slide_weeat_text'), img: wedareeat},
        {key: 2, h1: t('slide_yotei_title'), h2: t('slide_yotei_text'), img: yotei},

        ];

    const Solutions_var = [{icon:'lnr-cloud-sync', text: t('soluzioni_wedrive'), link:true, ref:"wedrive"},
        {icon:'lnr-dinner', text:t('soluzioni_weeat'), link:true, ref:"weeat"},
        {icon:'lnr-film-play', text:t('soluzioni_livetheater'), link:true, ref:"theater"},
        {icon:'lnr-pencil', text:t('soluzioni_wesign'), link:true, ref:"wecommerce"},
        {icon:'lnr-calendar-full', text:t('soluzioni_yotei'), link:true, ref:"yotai"},
    ];

    return(
    <div className="tipo wedareeatHome" onWheel={this.getScrollPosition}>
	<div className="row wrapPage">
            <MenuLanding/>
            <ShowCarousel slides={SlidesContent_var}/>
            <Quote 
            title={t('quote_title')}
            content1={t('quote_content1')}
            content2= {t('quote_content2')}
            content3={t('quote_content3')}
            content4={t('quote_content4')}
            content5={t('quote_content5')}
            />
            <ParallaxBg 
            image={1}
            />
            <PensiamoATuttoNoi
            content={Solutions_var}
            title={t('soluzioni_title')} subtitle={t('soluzioni_subtitle')}
            />

            <StartFromRight
            idref="wedrive"
            title={t('wedrive_title')} image={white}
            subtitle={t('wedrive_subtitle')}
            text1={t('wedrive_text')}
            />

            <StartFrom
             idref="weeat"
             title={t('weeat_title')} image={wedareeat}
             subtitle={t('weeat_subtitle')}
             text1={t('weeat_text')}
            />
            
            <StartFromRight
             idref="theater"
             title={t('wetheater_title')} image={livetheater}
             subtitle={t('wetheater_subtitle')}
             text1={t('wetheater_text')}

            />

            <StartFrom
            idref="yotai"
            title={t('yotei_title')} image={yotei}
            subtitle={t('yotei_subtitle')}
            text1={t('yotei_text')}

            />

            <StartFromRight
            idref="wecommerce"
             title={t('wesign_title')} image={white}
             subtitle={t('wesign_subtitle')}
             text1={t('wesign_text')}
            />
            
            <ContattiSec1 />
            <MobileMenu />
    </div>
    </div>
    );}
}

export const MyComponent = withTranslation()(Landing)