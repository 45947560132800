import React from 'react';
import '../../css/wedareEat.css';
import '../../css/animate.css';



export function ParallaxBg(props){
    return(
        <div className={`row bgImgRowWrap parallaxEffectCss
         ${props.image === 1 ? "bgBlockParallaxland" : "bgBlockParallax"}`}
        ></div>
    );
}