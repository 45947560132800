import React from 'react';
import '../../css/wedareEat.css';
import '../../css/animate.css';
import img7 from '../../img/eat/mockupwebapp2.jpg';
import img8 from '../../img/eat/mockupwebapp2.jpg';
import img9 from '../../img/eat/mockupwebapp3.jpg';
import img10 from '../../img/eat/mockupwebapp4.jpg';
import whiteMockup1 from '../../img/WhiteMockups/smartphoneswhite.png';
import whiteMockup2 from '../../img/WhiteMockups/smartphoneswhite_2.png';
import whiteMockup3 from '../../img/WhiteMockups/smartphoneswhite_3.png';
import websiteScreens1 from '../../img/WhiteMockups/websiteScreen1.png';
import {SliderComponent} from '../index';
import Slider from "react-slick";

export class ShowCarousel extends React.Component{
    

    render(){

        const settings = {
            infinite: true,
            dots: true,
            arrows:false,
            autoplay: true,
            autoplaySpeed: 3000,
            fade: true,
            dotsClass: "slick-dots-custom",
            cssEase: 'linear',
            responsive: [
            {
                breakpoint: 768,
                settings: {
                    fade:false,
                }
            }]
 };

    return(

        <div className="row topSlider homeSlider"> 
        <Slider {...settings}>
                {this.props.slides.map(key => {return(<SliderComponent  h1={key.h1} h2={key.h2} img={key.img}/>)})}
        </Slider> 
        </div>  

    );}
}