import React from 'react';
import axios from 'axios';
import { withTranslation } from 'react-i18next';
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

const SITE_KEY = "6Lc_t88aAAAAAIG2kj7LFUc8tCN8yrLvb2yK-hqX";

var formData = new FormData();




export class ContactForm extends React.Component{
constructor(props){
    super(props);
    this.state = {messaggio: "messaggio",
     newsletter: false,
      informativa: false, 
      oggetto: 'WeCommerce', 
      token: '', 
      nome: '', 
      cognome: '', 
      attivita: '', 
      email: '',
    isSubmitActive: false,
  }
    
	this.handleChange = this.handleChange.bind(this);
	this.handleSubmit = this.handleSubmit.bind(this);
}

 notify = () => toast("Wow so easy !");

options = ["WeDrive", "WeDare.eat", "Live theater", "WeSign", "Yotei booking", 'altro'];


componentDidMount(){
  const loadScriptByURL = (id, url, callback) => {
    const isScriptExist = document.getElementById(id);
 
    if (!isScriptExist) {
      var script = document.createElement("script");
      script.type = "text/javascript";
      script.src = url;
      script.id = id;
      script.onload = function () {
        if (callback) callback();
      };
      document.body.appendChild(script);
    }
 
    if (isScriptExist && callback) callback();
  }
 
  // load the script by passing the URL
  loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`, function () {
    console.log("Script loaded!");
  });
}

handleChange(event){
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
        [name]: value,
    });
}


handleSubmit(e){
  e.preventDefault();
  
  window.grecaptcha.ready(() => {
    window.grecaptcha.execute(SITE_KEY, { action: 'submit' }).then(token => {
      formData.append("nome", this.state.nome);
      formData.append("cognome", this.state.cognome );
      formData.append("attivita", this.state.attivita);
      formData.append("email", this.state.email);
      formData.append("informativa", this.state.informativa);
      formData.append("oggetto", this.state.oggetto);
      formData.append("messaggio", this.state.messaggio);
      this.submitData(token);
    });
  });
}
 
submitData = token => {
  formData.append("token", token);
  
  
  this.state.token = token;
  axios.post('process.php',
          formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        ).then((response)=>{
    
    if (response.data.result === 'success') {
      toast.success('Abbiamo ricevuto la tua mail, ti ricontatteremo al più presto.', {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      
        progress: undefined,
        });
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
        this.state.isSubmitActive = true;
    } else {
      toast.error('Ops qualcosa è andato storto, perfavore riprova tra poco ', {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
    }
  })
}



  onChange(value) {
    console.log("Captcha value:", value);
  };

  render(){
      const { t } = this.props;

        return(
          <form className="mainForm" onSubmit={this.handleSubmit}>
            <div className="formGroup">
              <input className="blackInput" type="text" name="nome" placeholder={t('contatti_name')} required value={this.state.nome} onChange={this.handleChange} />
            </div>
            <div className="formGroup">
              <input className="blackInput" type="text" name="cognome" placeholder={t('contatti_cognome')} required value={this.state.cognome} onChange={this.handleChange} />
            </div>
            <div className="formGroup">
              <input className="blackInput" type="text" name="attivita" placeholder={t('contatti_attivita')} value={this.state.attivita} onChange={this.handleChange} />
            </div>
            <div className="formGroup">
              <input className="blackInput" type="email" name="email" placeholder="E-mail*" required value={this.state.email} onChange={this.handleChange} />
            </div>
            <div className='formGroup'>
              <label style={{color: 'white'}}>{t('contatti_oggetto_label')}</label>
              <select className="blackInput" name='oggetto' value={this.state.oggetto} onChange={this.handleChange} >

                  {this.options.map((key) => 
                    <option>{key}</option>
                  )}

              </select>
            </div>

            <div className="formGroup littleInput w100">						
              <input  type="checkbox" name="informativa" checked={this.state.informativa} onChange={this.handleChange} required/>
              <label className="WhiteText">{t('contatti_accept')} <a href="#" title="">[leggi]</a></label>
            </div>
            <div className="formGroup w100">
              <textarea className="blackInput" name="messaggio" placeholder={t('contatti_message')} value={this.state.messaggio} onChange={this.handleChange} />
            </div>

             <button className="btn" type="submit" style={{marginBottom: '5rem'}} >{t('contatti_button')}</button>
					 
           <ToastContainer
            position="bottom-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            />
			</form>
        );
    }
}


export const FormContatti = withTranslation()(ContactForm)