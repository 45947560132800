import logo from './img/wecommerce-logo-flat.png';
import './App.css';
import {Home} from '../src/views/Home';
import {Caratteristiche} from '../src/views/Caratteristiche';
import {Prezzi} from '../src/views/Prezzi';
import {Contatti} from '../src/views/Contatti';
import ScrollToTop from './ScrollToTop';
import {Landing, MyComponent} from './views/Landing';


import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from 'react-router-dom';


function App() {

  const preRoute = '';


  return (
  <Router>
  <ScrollToTop>
        <Switch>
          <Route  path={`${preRoute}/wedare`}>
            <MyComponent />
          </Route>
            <Route path={`${preRoute}`}>
              <MyComponent />
            </Route>
        </Switch>
   </ScrollToTop>
    </Router>

  );
}

export default App;
