import React from 'react';
import webMockupCaract from '../../img/eat/webMockupCaract.jpg';
import screenswebsite from '../../img/WhiteMockups/screenswebsite.png';
import '../../css/wedareEat.css';
import '../../css/animate.css';

export function CaratteristicheSec5(){
    return(
        <section className="row sectionBlock bgSolo">
        <div className="blockOverflow">
            
            <div className="textBox animateEl  " data-animate="fadeInUp" data-duration=".6s" data-delay="0.2s">
                <header className="row">
                    <h1>Farai parte di un grande progetto</h1>
                    <h2>L'opportunità di scoprire tutto il potenziale della tua attività</h2>
                </header>
        
                <p>Potrai configurare il tuo negozio in base alle tue esigenze: fatti trovare dagli utenti vicini a te o dall'altra parte del mondo, gestisci gli ordini online con facilità o invita i tuoi clienti a prenotare un prodotto e passare in negozio. Tutto mentre ti tieni aggiornato con i nostri consigli per migliorare l'esperienza di vendita.</p>
                <p><b>Le possibilità con WeCommerce sono infinite! vendi oltre i limiti dell'e-commerce.</b></p>
                <a className="btn" href="prezzi.html" title="Acquistalo subito">Guarda un esempio</a>
            </div>	
            <img src={screenswebsite} className="animateEl  " data-animate="fadeIn" data-duration=".6s" data-delay="0.2s" alt="" />			
        </div>
    </section>
    );
}