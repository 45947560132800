import React from 'react';
import '../../css/wedareEat.css';
import '../../css/animate.css';
import logo from '../../img/wedare_bgBlack_03.png';
import { HashLink as Link } from 'react-router-hash-link';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { withTranslation } from 'react-i18next';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    useLocation
  } from 'react-router-dom';
  



export class Menu extends React.Component {



render(){
    const { t } = this.props;

    return(
        <div className="row top" >
        <div className="row superTop">
            
        </div>
        <div className="row mainMenu" id="mainMenu">
            <header>
                <Link to="/wedare"><h1><a className="logoTop" href="/" title="Wedare EAT, l'applicazione perfetta per la tua attività di ristorazione"><img src={logo} alt="Wedare EAT, l'applicazione perfetta per la tua attività di ristorazione" /></a></h1></Link>
            </header>
            <ul>
                 <AnchorLink href="#who"><li ><a href="index.html" title="">{t('menu_chi')}</a></li></AnchorLink>
                 <AnchorLink href="#prods"><li ><a href="caratteristiche.html" title="Caratteristiche">{t('menu_cosa')}</a></li></AnchorLink>
                <AnchorLink href="#conts"><li ><a href="contatti.html" title="Contatti">{t('menu_contatti')}</a></li></AnchorLink>
            </ul>
        </div>
    </div>
    );
}}


export const MenuLanding = withTranslation()(Menu)